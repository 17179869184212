<template>
    <v-expansion-panels v-model="panel" class="basket-summary mb-5">
        <v-skeleton-loader
            v-if="basketStore.shouldShowLoader"
            class="basket-summary__skeleton w-100"
            type="heading"
        >
        </v-skeleton-loader>
        <v-expansion-panel
            v-else
            :bg-color="
                theme.name.value === 'light' ? 'background' : 'gray-1000'
            "
        >
            <v-expansion-panel-title class="border-bottom">
                {{
                    panel === 0
                        ? t("basket.summary.hide")
                        : t("basket.summary.show")
                }}
                <v-icon class="ml-3">
                    {{ panel === 0 ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>

                <template v-slot:actions>
                    <span class="total font-weight-bold">
                        {{ total }}
                    </span>
                </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <BasketSubscriptionSummary
                    v-if="basketStore.recurringItems.length > 0"
                    :currency="basketStore.currencyCode"
                    :recurring-items="basketStore.recurringItems"
                    :tax-mode="basketStore.tax?.taxMode"
                    :tax-name="basketStore.tax?.name"
                />

                <BasketCodes
                    v-if="
                        !basketStore.isBasketCompleted &&
                        !basketStore.isPaymentMethodUpdate &&
                        (appStore.config?.hasCreatorCodes ||
                            appStore.config?.hasGiftCards ||
                            appStore.config?.hasCoupons)
                    "
                    class="mb-3"
                />

                <div v-if="!basketStore.isCurrencySupported">
                    {{
                        $t("basket.currency_not_supported", {
                            converted_to:
                                paymentMethodStore.selectedPaymentMethodDetails
                                    ?.currency,
                            converted_from: basketStore.currencyCode,
                        })
                    }}
                </div>

                <BasketTotal
                    class="w-100"
                    :total="basketStore.total"
                    :balance="basketStore.balance"
                    :sub-total="basketStore.subTotal"
                    :sales-tax="basketStore.salesTax"
                    :gift-cards="basketStore.giftCards"
                    :coupons="basketStore.coupons"
                    :currency="basketStore.currencyCode"
                    :should-show-conversion="basketStore.showConversion"
                    :conversion-currency="
                        paymentMethodStore.selectedPaymentMethodDetails
                            ?.currency
                    "
                    :conversion-price="basketStore.totalWithConversion"
                    :recurring="basketStore.isRecurring"
                    :recurringNextPaymentDate="
                        basketStore.recurringNextPaymentDate
                    "
                    :recurringPeriod="basketStore.recurringPeriod"
                    :recurringAmount="basketStore.recurringAmount"
                    :is-payment-method-update="
                        basketStore.isPaymentMethodUpdate
                    "
                    :recurring-items="basketStore.recurringItems"
                />
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script setup lang="ts">
// Components
import BasketTotal from "@/components/basket/BasketTotal.vue";
import BasketCodes from "@/components/basket/BasketCodes.vue";
import BasketSubscriptionSummary from "@/components/basket/BasketSubscriptionSummary.vue";
import { useI18n } from "vue-i18n";

// Store
import { useBasketStore } from "@/store/basket";
import { ref, type Ref } from "vue";
import { useTheme } from "vuetify/lib/framework.mjs";
import { useAppStore } from "@/store/app";
import { usePaymentMethodStore } from "@/store/payment-method";
import { computed } from "vue";
import { useCurrencyFormatter } from "@/composables/useCurrencyFormatter";
import { storeToRefs } from "pinia";

const basketStore = useBasketStore();
const appStore = useAppStore();
const paymentMethodStore = usePaymentMethodStore();

const { t, locale } = useI18n();

const panel: Ref<number | undefined> = ref();
const theme = useTheme();

const { formatCurrency } = useCurrencyFormatter(
    locale,
    basketStore.currencyCode,
);

const total = computed(() => {
    if (
        basketStore.showConversion &&
        paymentMethodStore.selectedPaymentMethodDetails?.currency
    ) {
        return formatCurrency(
            basketStore.totalWithConversion,
            paymentMethodStore.selectedPaymentMethodDetails.currency,
        );
    }

    return formatCurrency(basketStore.balance);
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/theme";
@use "sass:math";

.total {
    font-size: math.div(16, 14) * 1rem;
}

:deep(.v-expansion-panel) {
    --v-theme-overlay-multiplier: 0;
}

:deep(.v-expansion-panel__shadow) {
    box-shadow: none;
}

.basket-summary {
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    width: calc(100% + 48px);

    &__skeleton {
        padding: 0 15px;
        overflow: hidden;
        max-height: 52px;
    }
}
</style>
