<script lang="ts" setup>
import { type RendererElement, useAttrs, h, Teleport } from "vue";
import { useRender } from "@/composables/useRender";

interface Props {
    tag: string;
    to?: string | RendererElement | null | undefined;
    skip: boolean;
}

defineOptions({
    inheritAttrs: false,
});

const props = withDefaults(defineProps<Props>(), {
    tag: "div",
    to: null,
    skip: false,
});
const slots = defineSlots<{
    default?: any;
}>();
const attrs = useAttrs();

useRender(() =>
    props.skip
        ? slots.default?.()
        : props.tag === "Teleport"
        ? h(Teleport, { to: props.to }, [slots.default?.()])
        : h(props.tag, { ...attrs }, [slots.default?.()]),
);
</script>
