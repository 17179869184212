/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import "@/styles/main.scss";

// Icons
import { mdi } from "vuetify/iconsets/mdi";
import { aliases, custom } from "../icons";

// Composables
import { createVuetify } from "vuetify";

// Themes
import * as themes from "@/themes";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    defaults: {
        global: {
            ripple: false,
        },
        VTextField: {
            variant: "outlined",
            bgColor: "fields",
            border: "field-border",
        },
        VTextarea: {
            variant: "outlined",
            bgColor: "fields",
            border: "field-border",
        },
        VOtpInput: {
            variant: "outlined",
            bgColor: "fields",
            border: "field-border",
        },
        VCombobox: {
            variant: "outlined",
            bgColor: "fields",
            border: "field-border",
        },
        VBtn: { elevation: 0 },
        VBreadcrumbs: { activeColor: "primary" },
        VSkeletonLoader: { color: "transparent" },
        VCheckbox: {
            color: "primary",
            falseIcon: "custom:checkbox-off",
            trueIcon: "custom:checkbox-on",
        },
    },
    theme: {
        defaultTheme: "light",
        themes: {
            ...themes,
        },
    },
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
            custom,
        },
    },
    display: {
        mobileBreakpoint: "sm",
        thresholds: {
            sm: 700,
        },
    },
    // https://vuetifyjs.com/en/features/internationalization/#custom-adapters
    // locale: {
    //     adapter: createVueI18nAdapter({ i18n, useI18n }),
    // },
});
