<template>
    <v-footer
        :height="width > 780 ? 60 : 105"
        :app="smAndUp"
        order="-1"
        name="footer"
        class="flex-wrap"
    >
        <RenderComponentOrSkip
            class="d-flex flex-nowrap"
            :skip="smAndUp"
            tag="div"
        >
            <a
                :href="
                    'https://www.tebex.io?utm_source=checkout&utm_campaign=' +
                        appStore.config?.id ?? ''
                "
                target="_blank"
                class="align-self-center"
            >
                <img
                    src="/logo-gray.svg"
                    alt="Tebex Logo"
                    class="mr-6 footer-logo"
                />
            </a>
            <span class="d-flex align-center description mr-6">
                {{ t("footer.description") }}
            </span>
        </RenderComponentOrSkip>
        <div class="d-flex footer-links">
            <a
                :href="`https://checkout.tebex.io/baskets/${basketStore.basketId}/refund-policy`"
                target="_blank"
            >
                {{ t("footer.links.refund_policy") }}
            </a>
            <a href="https://checkout.tebex.io/impressum" target="_blank">
                {{ t("footer.links.impressum") }}
            </a>
            <a href="https://checkout.tebex.io/terms" target="_blank">
                {{ t("footer.links.terms_and_conditions") }}
            </a>
            <a href="https://checkout.tebex.io/privacy" target="_blank">
                {{ t("footer.links.privacy_policy") }}
            </a>
            <LocaleSwitcher />
        </div>
    </v-footer>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify";
import RenderComponentOrSkip from "@/components/helpers/RenderComponentOrSkip.vue";
import LocaleSwitcher from "./LocaleSwitcher.vue";
import { useAppStore } from "@/store/app";
import { useBasketStore } from "@/store/basket";

const { width, smAndUp } = useDisplay();
const { t } = useI18n();
const appStore = useAppStore();
const basketStore = useBasketStore();
</script>

<style lang="scss" scoped>
@use "@/styles/settings";

img {
    width: 60px;
}

.v-footer {
    font-size: settings.$footer-font-size;
    max-height: 105px;
    align-items: inherit;

    @media #{map-get(settings.$display-breakpoints, 'sm')} {
        .footer-content {
            max-width: 85%;
        }
    }

    @media #{map-get(settings.$display-breakpoints, 'sm-and-down')} {
        flex-direction: row;

        .footer-content {
            max-width: none;
        }
    }

    a {
        color: #9c9fa9;
    }

    @at-root {
        .v-theme--dark & {
            background-color: #0a0a0c;
            color: #9c9fa9;
        }
    }
}

.footer-links {
    gap: 16px;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;

    @media #{map-get(settings.$display-breakpoints, 'sm-and-up')} {
        justify-content: flex-end;
        margin-right: 16px;
    }
}

.footer-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
}

.description {
    @media #{map-get(settings.$display-breakpoints, 'md-and-down')} {
        max-width: 275px;
        margin: 0 !important;
        display: flex;
        align-items: center;
    }
    color: #9c9fa9;
}

#history {
    font-size: 11px;
}
</style>
