<template>
    <div
        v-if="payment?.txnId"
        class="payment-completed d-flex flex-column align-center h-100"
    >
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25 45C36.0457 45 45 36.0457 45 25C45 13.9543 36.0457 5 25 5C13.9543 5 5 13.9543 5 25C5 36.0457 13.9543 45 25 45ZM35.596 19.756C36.3817 18.8131 36.2544 17.4119 35.3115 16.6262C34.3687 15.8405 32.9674 15.9679 32.1817 16.9107L22.6284 28.3746L17.6825 23.4286C16.8146 22.5608 15.4076 22.5608 14.5398 23.4286C13.6719 24.2965 13.6719 25.7035 14.5398 26.5713L21.2064 33.238C21.6479 33.6795 22.2548 33.9149 22.8785 33.8866C23.5022 33.8583 24.0852 33.5689 24.4849 33.0893L35.596 19.756Z"
                fill="#00CC45"
            />
        </svg>

        <h1>{{ t("payment.order_complete") }}</h1>

        <p class="order-description">
            {{
                t("payment.order_complete_description", {
                    total: formatCurrency(payment.amount),
                })
            }}
        </p>

        <div
            @click="copy(payment.txnId)"
            class="order-number px-6"
            data-testid="order-number"
        >
            {{ t("payment.order_number") }}
            <span>{{ payment.txnId }}</span>
        </div>

        <div
            v-if="payment.recurringPayments?.length > 0"
            class="subscription-details py-3 px-4 border"
        >
            <h3 class="mb-3">{{ t("payment.subscription_details") }}</h3>

            <v-table>
                <tbody>
                    <tr
                        v-for="item in payment.recurringPayments"
                        :key="item.id"
                    >
                        <td>{{ item.reference }}</td>

                        <td>{{ item.itemName }}</td>

                        <td data-testid="next-recurring-payment">
                            <i18n-t
                                keypath="payment.subscription_next_payment_of_date"
                                tag="span"
                                scope="global"
                                class="text-no-wrap"
                            >
                                <template #date>
                                    <span class="font-weight-bold">
                                        {{
                                            nextPaymentDateFormatted(
                                                item.nextPaymentDate,
                                            )
                                        }}
                                    </span>
                                </template>
                                <template #amount>
                                    <span class="font-weight-bold">
                                        {{ formatCurrency(item.amount) }}
                                    </span>
                                </template>
                            </i18n-t>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </div>

        <i18n-t keypath="payment.contact_us_description" tag="p" scope="global">
            <template #contactLink>
                <a
                    href="https://www.tebex.io/support-customer"
                    class="link"
                    target="_blank"
                >
                    {{ $t("payment.contact_us") }}
                </a>
            </template>
            <template #paymentHistoryLink>
                <a
                    href="https://checkout.tebex.io/payment-history"
                    class="link"
                    target="_blank"
                >
                    {{ $t("payment.payment_history") }}
                </a>
            </template>
        </i18n-t>

        <LoginWithDiscord
            :discord-login-url="payment.discordLoginUrl"
            :is-logged-in="isLoggedIn"
            @discord-login-complete="onDiscordLogin"
        />

        <div
            v-if="appStore.config?.shouldDisplayOffers"
            class="advertisement border"
        >
            <Rokt
                v-if="appStore.config?.id"
                :vendorId="appStore.config?.id.toString()"
                :payment="payment"
            />
        </div>

        <v-btn class="mt-auto" color="secondary" @click="onContinue">
            {{ t("buttons.continue") }}
        </v-btn>
    </div>
</template>

<script setup lang="ts">
import { onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useZoid } from "@/composables/useZoid";
import { useBasketStore } from "@/store/basket";
import { BasketPayment } from "@/types/Basket";
import LoginWithDiscord from "@/components/discord/LoginWithDiscord.vue";
import { useAppStore } from "@/store/app";
import { useClipboard, useDateFormat } from "@vueuse/core";
import { ref } from "vue";
import Rokt from "@/components/rokt/Rokt.vue";
import { useToastStore } from "@/store/toast";
import { useCurrencyFormatter } from "@/composables/useCurrencyFormatter";

const { t, locale } = useI18n();
const basketStore = useBasketStore();
const router = useRouter();
const route = useRoute();
const zoid = useZoid();
const appStore = useAppStore();

const payment = computed(() => basketStore.payment as BasketPayment);

const { formatCurrency } = useCurrencyFormatter(
    locale,
    computed(
        () => payment.value.checkoutCurrency ?? payment.value.currency ?? "USD",
    ),
);

// No payment information available, redirect to basket
onMounted(async () => {
    if (!payment.value) {
        // Re-fetch basket
        await basketStore.fetchBasket(route.params.basketId as string);

        if (!payment.value) {
            router.push({
                name: "basket",
                params: { basketId: basketStore.basketId },
            });
        }
    }
});

const nextPaymentDateFormatted = (nextPaymentDate: string) => {
    const date = useDateFormat(nextPaymentDate, "MMM Do, YYYY", {
        locales: appStore.locale.replace("_", "-"),
    });

    return date.value;
};

const onContinue = () => {
    if (zoid.isChild()) {
        zoid.xprops.onPaymentComplete(basketStore.basketData);
        zoid.xprops.onClosePopup();
    } else if (basketStore.completeUrl) {
        window.location.href = basketStore.completeUrl;
    }
};

const isLoggedIn = ref(false);

const onDiscordLogin = async () => {
    // Re-fetch basket
    await basketStore.fetchBasket(route.params.basketId as string);

    // If the url is gone we know it was successful
    if (payment.value.discordLoginUrl === null) {
        isLoggedIn.value = true;
    }
};

const clipboard = useClipboard();
const toastStore = useToastStore();

const copy = (text: string) => {
    clipboard.copy(text);
    toastStore.addToast(t("ui.copied"), {
        type: "success",
    });
};
</script>

<style lang="scss" scoped>
.payment-completed {
    gap: 20px;
    padding: 35px;
    text-align: center;
}

p {
    max-width: 90%;
}
.order-number {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    border: 1px solid rgb(var(--v-theme-gray-900));
    background-color: rgb(var(--v-theme-background));
    font-size: 18px;

    @at-root {
        .v-theme--dark & {
            color: rgb(var(--v-theme-gray-300));
        }

        .v-theme--light & {
            color: rgb(var(--v-theme-gray-500));
        }
    }
}

.advertisement {
    margin-top: 3rem;
    padding: 1rem;
    max-width: 800px;
    width: 100%;
}
</style>
