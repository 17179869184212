<template>
    <div class="d-flex flex-column h-100 pa-5">
        <v-skeleton-loader
            :loading="basketStore.shouldShowLoader"
            type="text"
            class="d-flex justify-space-between"
        >
            <span v-if="basketStore.username">
                {{
                    basketStore.isRecurring
                        ? t("basket.sidebar.names_subscription", {
                              name: basketStore.username,
                          })
                        : t("basket.sidebar.names_order", {
                              name: basketStore.username,
                          })
                }}
            </span>
            <span v-else>
                {{
                    basketStore.isRecurring
                        ? t("basket.sidebar.your_subscription")
                        : t("basket.sidebar.your_order")
                }}
            </span>
            <a
                v-if="
                    !basketStore.isPaymentInProgress && !basketStore.isComplete
                "
                href="#"
                class="link"
                @click="cancel"
            >
                {{ t("basket.sidebar.cancel") }}
            </a>
        </v-skeleton-loader>

        <v-skeleton-loader
            v-if="basketStore.shouldShowLoader"
            class="py-6"
            type="list-item-avatar-two-line@2"
        />

        <BasketOverview
            v-else
            :items="basketStore.items"
            :currency-code="basketStore.currencyCode"
        />

        <SkeletonLoaderForm
            class="py-6 mt-auto"
            :quantity="2"
            :loading="basketStore.shouldShowLoader"
            height="234px"
        >
            <BasketCodes
                v-if="
                    !basketStore.isBasketCompleted &&
                    !basketStore.isPaymentMethodUpdate &&
                    (appStore.config?.hasCreatorCodes ||
                        appStore.config?.hasGiftCards ||
                        appStore.config?.hasCoupons)
                "
                class="w-100 pb-3"
            />
        </SkeletonLoaderForm>

        <v-sheet
            v-if="!basketStore.isCurrencySupported"
            class="currency-not-supported"
            color="fields"
        >
            {{
                $t("basket.currency_not_supported", {
                    converted_to:
                        paymentMethodStore.selectedPaymentMethodDetails
                            ?.currency,
                    converted_from: basketStore.currencyCode,
                })
            }}
        </v-sheet>

        <v-skeleton-loader
            type="text@3"
            :loading="basketStore.shouldShowLoader"
            height="94px"
        >
            <BasketTotal
                class="w-100"
                :total="basketStore.total"
                :balance="basketStore.balance"
                :sub-total="basketStore.subTotal"
                :sales-tax="basketStore.salesTax"
                :gift-cards="basketStore.giftCards"
                :coupons="basketStore.coupons"
                :currency="basketStore.currencyCode"
                :should-show-conversion="basketStore.showConversion"
                :conversion-currency="
                    paymentMethodStore.selectedPaymentMethodDetails?.currency
                "
                :conversion-price="basketStore.totalWithConversion"
                :recurring="basketStore.isRecurring"
                :recurringNextPaymentDate="basketStore.recurringNextPaymentDate"
                :recurringPeriod="basketStore.recurringPeriod"
                :recurringAmount="basketStore.recurringAmount"
                :is-payment-method-update="basketStore.isPaymentMethodUpdate"
                :recurring-items="basketStore.recurringItems"
            />
        </v-skeleton-loader>
    </div>
</template>

<script setup lang="ts">
// Components
import BasketOverview from "@/components/basket/BasketOverview.vue";
import BasketTotal from "@/components/basket/BasketTotal.vue";
import BasketCodes from "@/components/basket/BasketCodes.vue";
import SkeletonLoaderForm from "@/components/skeleton/SkeletonLoaderForm.vue";
import { useI18n } from "vue-i18n";

// Store
import { useBasketStore } from "@/store/basket";
import { useAppStore } from "@/store/app";

// Composables
import { useZoid } from "@/composables/useZoid";
import { usePaymentMethodStore } from "@/store/payment-method";

const zoid = useZoid();

const basketStore = useBasketStore();
const appStore = useAppStore();
const paymentMethodStore = usePaymentMethodStore();

const { t } = useI18n();

const cancel = () => {
    if (zoid.isChild()) {
        zoid.xprops.onClosePopup();
    } else if (basketStore.cancelUrl) {
        window.open(basketStore.cancelUrl, "_self");
    }
};
</script>

<style scoped lang="scss">
.currency-not-supported {
    padding: 1rem;
    margin: 2rem 0;
}
</style>
