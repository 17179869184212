<template>
    <div class="basket__total">
        <div
            class="d-flex justify-space-between pb-3 text-gray-300 font-weight-bold text-sm"
        >
            <span id="sub-total">{{ t("basket.sub_total") }}</span>
            <span aria-labelledby="sub-total">
                {{ formatCurrency(subTotal) }}
            </span>
        </div>

        <div class="w-100 border-b mb-3"></div>

        <span
            v-if="
                coupons.length > 0 ||
                (basketStore.creatorCode &&
                    basketStore.creatorCode.discountAmount > 0)
            "
            class="d-flex justify-space-between pb-2 text-gray-300 text-sm"
        >
            {{ t("basket.discounts") }}
        </span>

        <!-- The applied creator code -->
        <div
            v-if="
                basketStore.creatorCode &&
                basketStore.creatorCode.discountAmount > 0
            "
            class="d-flex justify-space-between pb-3 text-sm"
            data-testid="creator_code_discount"
        >
            <span>
                <v-chip
                    :id="basketStore.creatorCode.code"
                    data-testid="creator_code"
                    color="secondary"
                    variant="flat"
                >
                    {{ basketStore.creatorCode.code }}
                </v-chip>
            </span>
            <span
                :aria-labelledby="basketStore.creatorCode.code"
                data-testid="discount_amount"
                class="text-gray-300"
            >
                {{
                    formatCurrency(
                        Math.abs(basketStore.creatorCode.discountAmount) * -1,
                    )
                }}
            </span>
        </div>

        <!-- Discounts  -->
        <div
            v-for="coupon in coupons"
            :key="coupon.code"
            class="d-flex justify-space-between pb-3 text-sm"
        >
            <span>
                <v-chip
                    :id="coupon.code"
                    data-testid="discount_code"
                    @click="removeCoupon(coupon.id)"
                    append-icon="$close"
                    :color="theme.current.value.dark ? 'gray-900' : 'gray-400'"
                    variant="flat"
                    :disabled="beingRemoved.has(coupon.id)"
                >
                    {{ coupon.code }}
                    <template v-if="beingRemoved.has(coupon.id)" #append>
                        <v-progress-circular
                            class="ml-1"
                            :size="16"
                            :width="3"
                            indeterminate
                        ></v-progress-circular>
                    </template>
                </v-chip>
            </span>
            <span
                :aria-labelledby="coupon.code"
                data-testid="discount_amount"
                class="text-gray-300"
            >
                {{ formatCurrency(Math.abs(coupon.saving) * -1) }}
            </span>
        </div>

        <div
            v-if="salesTax > 0"
            class="d-flex justify-space-between pb-3 text-gray-300 text-sm"
        >
            <span id="sales-tax">{{ t("basket.sales_tax") }}</span>
            <span aria-labelledby="sales-tax">
                {{ formatCurrency(salesTax) }}
            </span>
        </div>

        <template v-if="showTotalBeforeGiftCards">
            <div
                class="d-flex justify-space-between pb-3 text-gray-300 font-weight-bold"
            >
                <span id="basket-total">{{ t("basket.total_price") }}</span>
                <span aria-labelledby="basket-total">
                    {{ total }}
                </span>
            </div>
            <!-- A list of the applied gift cards -->
            <div class="w-100 border-b mb-3"></div>
        </template>

        <template v-if="giftCards.length > 0">
            <span
                class="d-flex justify-space-between pb-2 text-gray-300 text-sm"
            >
                {{ t("basket.gift_cards") }}
            </span>
            <div
                v-for="giftCard in giftCards"
                :key="giftCard.cardNumber"
                class="d-flex justify-space-between pb-3"
            >
                <span>
                    <v-chip
                        :id="giftCard.cardNumber"
                        data-testid="discount_code"
                        @click="removeGiftCard(giftCard.id)"
                        append-icon="$close"
                        :disabled="beingRemoved.has(giftCard.id)"
                    >
                        {{ giftCard.cardNumber }}

                        <template v-if="beingRemoved.has(giftCard.id)" #append>
                            <v-progress-circular
                                class="ml-1"
                                :size="16"
                                :width="3"
                                indeterminate
                            ></v-progress-circular>
                        </template>
                    </v-chip>
                </span>
                <span
                    :aria-labelledby="giftCard.cardNumber"
                    data-testid="discount_amount"
                    class="text-gray-300"
                >
                    {{ formatCurrency(Math.abs(giftCard.deduction) * -1) }}
                </span>
            </div>
            <div class="w-100 border-b mb-3"></div>
        </template>
        <div
            class="d-flex justify-space-between font-weight-bold"
            :class="hasRecurringItems ? 'text-h6' : 'text-h5'"
        >
            <span id="basket-balance">{{ balanceLabel }}</span>
            <span aria-labelledby="basket-balance">
                {{ balance }}
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useBasketStore } from "@/store/basket";
import type { Coupon } from "@/types/Coupon";
import type { GiftCard } from "@/types/GiftCard";
import { ref, computed } from "vue";
import { useTheme } from "vuetify/lib/framework.mjs";
import { useCurrencyFormatter } from "@/composables/useCurrencyFormatter";
import { RecurringItem } from "@/types/RecurringItems";

interface Props {
    // The total amount of the basket
    total: number;
    // The amount to pay
    balance: number;
    subTotal: number;
    salesTax: number;
    currency: string;
    coupons: Coupon[];
    giftCards: GiftCard[];
    shouldShowConversion: boolean;
    conversionCurrency?: string;
    conversionPrice?: number;
    recurringNextPaymentDate: string | null;
    recurringAmount: number | null;
    isPaymentMethodUpdate: boolean;
    recurringItems: RecurringItem[];
}

const props = defineProps<Props>();

const { t, locale } = useI18n();
const theme = useTheme();
const basketStore = useBasketStore();

const { formatCurrency } = useCurrencyFormatter(locale, props.currency);

const beingRemoved = ref(new Set());

const removeGiftCard = async (id: string) => {
    beingRemoved.value.add(id);

    await basketStore.removeGiftCard(id);

    beingRemoved.value.delete(id);
};

const removeCoupon = async (id: string) => {
    beingRemoved.value.add(id);

    await basketStore.removeCoupon(id);

    beingRemoved.value.delete(id);
};

const hasRecurringItems = computed(() => props.recurringItems.length > 0);

const balanceLabel = computed(() => {
    if (hasRecurringItems.value) {
        if (props.isPaymentMethodUpdate) {
            return (
                t("basket.to_pay_next") + " " + props.recurringNextPaymentDate
            );
        }

        return t("basket.to_pay_now");
    }

    // If there have been some discounts applied
    if (props.giftCards.length > 0) {
        return t("basket.left_to_pay");
    }

    return t("basket.total_price");
});

const balance = computed(() => {
    if (props.shouldShowConversion && props.conversionPrice) {
        return formatCurrency(props.conversionPrice, props.conversionCurrency);
    }

    return formatCurrency(props.balance);
});

const total = computed(() => {
    return formatCurrency(props.total);
});

const showTotalBeforeGiftCards = computed(() => {
    const hasDiscounts = props.coupons.length > 0 || basketStore.creatorCode;
    const hasGiftCards = props.giftCards.length > 0;
    const hasSalesTax = props.salesTax > 0;

    return (hasDiscounts && hasGiftCards) || (hasGiftCards && hasSalesTax);
});
</script>

<style setup lang="scss">
@use "@/styles/settings";

.v-chip__append .v-icon {
    font-size: settings.$chip-icon-font-size;
}

.subscriptions {
    padding: 8.5px 10px;
}

.text-sm {
    font-size: 12px;
}

.v-chip__content {
    line-height: 1rem;
}
</style>
