<!-- eslint-disable vue/valid-v-model -->
<template>
    <div v-if="config && form" class="form-grid">
        <div v-for="field in config.fields" :key="field.name">
            <label :for="field.name">
                {{ field.label }}
            </label>

            <!-- Text -->
            <v-text-field
                v-if="field.type === 'text'"
                v-model="form.fields[field.name].model"
                v-bind="form.fields[field.name].props"
                :id="field.name"
                :name="field.name"
            ></v-text-field>

            <!-- Select -->
            <v-select
                v-if="field.type === 'select'"
                v-model="form.fields[field.name].model"
                v-bind="form.fields[field.name].props"
                :id="field.name"
                :name="field.name"
                :items="field.items"
                item-title="name"
                item-value="id"
                variant="outlined"
            ></v-select>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PaymentFormContext, usePaymentStore } from "@/store/payment";
import { FieldsDynamic } from "@/types/PaymentMethodFields";
import { toRef } from "vue";
import { onBeforeUnmount, onMounted } from "vue";
interface Props {
    config: FieldsDynamic;
    form: PaymentFormContext;
}

const props = defineProps<Props>();
const form = toRef(props, "form");

const paymentStore = usePaymentStore();

onMounted(() => {
    paymentStore.setFieldsReady(true);
});
onBeforeUnmount(() => {
    paymentStore.setFieldsReady(false);
});
</script>
